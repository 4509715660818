import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/common/Layout";
import "../styles/pages/services.scss";
import ContactForm from "../components/common/ContactForm";
import Seo from "../components/meta/Seo";

const Services = ({ data }) => {
  const { service, settings, allServices } = data;

  const [activeAccordionKey, setActiveAccordionKey] = useState(null);

  const [uniqueTags, setUniqueTags] = useState([]);

  useEffect(() => {
    const tags = allServices.nodes.map(serviceNode => serviceNode.tags[0]);
    const newUniqueTags = [...new Set(tags)];
    setUniqueTags(newUniqueTags);
  }, [allServices]);

  useState(() => {
    allServices.nodes.find(serviceNode => {
      if (
        serviceNode.data.service_title.text === service.data.service_title.text
      ) {
        setActiveAccordionKey(serviceNode.tags[0]);
      }
    });
  }, [service.data.service_title.text]);

  return (
    <div className="services">
      <Seo page={service} />
      <Layout>
        <div className="two-columns max-width ">
          <div className="px-4 side-nav">
            <Accordion
              activeKey={activeAccordionKey}
              onSelect={setActiveAccordionKey}
            >
              {uniqueTags.map(tag => (
                <Accordion.Item eventKey={tag} key={tag}>
                  <Accordion.Header className="no-border">
                    {tag}
                  </Accordion.Header>
                  <Accordion.Body>
                    {allServices.nodes
                      .filter(serviceNode => serviceNode.tags[0] === tag)
                      .map(serviceNode => (
                        <Link
                          activeClassName="active-link"
                          key={serviceNode.id}
                          to={serviceNode.url}
                        >
                          {serviceNode.data.service_title.richText[0].text}
                        </Link>
                      ))}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          <div className="main-content">
            <div className="max-width px-4 main-image">
              <GatsbyImage
                style={{ borderRadius: 8 }}
                image={service.data.hero_image.gatsbyImageData}
                alt="hero image"
              />
            </div>
            <div className="max-width px-4 main-title">
              <PrismicRichText field={service.data.service_title.richText} />
            </div>
            <div className="max-width px-4 main-content">
              <PrismicRichText field={service.data.service_content.richText} />
            </div>
          </div>
        </div>
        <div className="form-color">
          <div className="max-width contact-form-container">
            <ContactForm className="contact-form mx-4" />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query serviceQuerys($lang: String, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    service: prismicServices(lang: { eq: $lang }, id: { eq: $id }) {
      url
      type
      data {
        meta_title
        meta_description
        service_title {
          richText
          text
        }
        service_content {
          richText
        }
        hero_image {
          gatsbyImageData
          alt
        }
        meta_image {
          gatsbyImageData
          alt
        }
        meta_title
      }
    }
    allServices: allPrismicServices {
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        totalCount
      }
      nodes {
        data {
          meta_description
          meta_title
          service_title {
            richText
            text
          }
        }
        tags
        url
      }
    }
  }
`;

export default Services;
